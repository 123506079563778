body {
  background-color: #101018;
}

.main-container {
  text-align: center;
  margin-top: 10rem;
}

.title-content {
  margin-top: 2rem;
  color: white;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 1000;
  font-size: 40px;
}

.sub-content {
  color: white;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 900;
  font-size: 18px;
}

.blurb-content {
  color: white;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  margin: auto;
  max-width: 600px;
  text-align: center;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
}
