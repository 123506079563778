.nav-style {
  background-color: #101018;
  font-size: 20px;
  font-family: "Nunito Sans", sans-serif;
}

.nav-transition {
  box-shadow: 1px 1px 1px #222;
  animation: moveDown 0.5s ease-in-out;
}

.sub-title {
  font-size: 16px;
  opacity: 0.7;
  margin-left: 8px;
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}
